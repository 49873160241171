<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="750"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        <div class="text-center">
          <h4>Masukkan Info Tambahan</h4>
        </div>
        <v-row>
          <v-col class="pa-0 d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoice.PAYMENT_PERIOD}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-select
                outlined
                dense
                @change="periodChange"
                :items="itemsPaymentMethod"
                v-model="form.paymentPeriod"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoice.PAYMENT_PERIOD)]"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoice.PERIOD}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-row v-if="typePeriod === 'WEEKLY'">
                <v-col>
                  <v-menu
                    ref="menuFromDate"
                    v-model="menuFromDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        class="caption"
                        :placeholder="$_strings.common.FROM_DATE"
                        :value="form.periodStart ? dateFormat(form.periodStart) : null"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.common.FROM_DATE)]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.periodStart"
                      no-title
                      :allowed-dates="allowDatesFrom"
                      @change="periodWeek"
                      locale="id"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-if="typePeriod === 'MONTHLY'">
                <v-col>
                  <v-menu
                    ref="menuMonthDate"
                    v-model="menuMonthDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        class="caption"
                        placeholder="Pilih Bulan"
                        :value="dateMonth ? dateFormat(dateMonth) : null"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.common.FROM_DATE)]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateMonth"
                      no-title
                      type="month"
                      @change="periodMonth"
                      locale="id"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-if="typePeriod === 'none'">
                <v-col>
                  <v-text-field
                    placeholder="Harap pilih periode pembayaran"
                    outlined
                    dense
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.nameBankLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.bankName"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.nameBankLabel)]"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.aCLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.bankAcc"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.aCLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.aNLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="form.bankAtt"
                v-mask="{'alias': 'custom-integer',rightAlign: false}"
                clearable
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.aNLabel)]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signerOneLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerLeftName"
                :rules="rules.signerLeftName"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signatoryOneLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerLeftPosition"
                :rules="rules.signerLeftPosition"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signerTwoLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerRightName"
                :rules="rules.signerRightName"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signatoryTwoLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerRightPosition"
                :rules="rules.signerRightPosition"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          small
          color="red white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          class="white--text"
          :disabled="isLoading"
          @click="createInvoiceFixedPrice"
        >
          {{$_strings.common.CREATE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.$refs.form.reset();
        this.form = {
          paymentPeriod: '',
          periodStart: null,
          periodEnd: null,
        };
        this.dateMonth = null;
        this.dateWeek = null;
        this.typePeriod = 'none';
        return;
      }
      this.invoiceCommonData();
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isLoadingCompanyList: false,
      menuFromDate: false,
      menuMonthDate: false,
      itemsCompany: [],
      itemsPaymentMethod: [
        {
          text: 'Bulanan',
          value: 'MONTHLY',
        },
        {
          text: 'Mingguan',
          value: 'WEEKLY',
        },
      ],
      form: {
        paymentPeriod: '',
        periodStart: null,
        periodEnd: null,
        bankAcc: '',
        bankAtt: '',
        bankName: '',
        signerLeftName: '',
        signerLeftPosition: '',
        signerRightName: '',
        signerRightPosition: '',
      },
      allowDatesFrom: '',
      allowDatesTo: '',
      dateMonth: '',
      dateWeek: '',
      typePeriod: 'none',
      rules: {
        signerLeftName: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerRightName: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerLeftPosition: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerRightPosition: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
      },
    };
  },
  methods: {
    dayjs,
    dateFormat,
    async createInvoiceFixedPrice() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.$dialog.notify.error('Mohon periksa kembali field yang wajib diisi');
        return;
      }
      try {
        this.isLoading = true;
        await this.$_services.invoice.createFixedPriceInvoice(this.form);
        this.$dialog.notify.success('Berhasil menambahkan invoice');
        this.dialog = false;
        this.$emit('fetchInvoice');
      } finally {
        this.isLoading = false;
      }
    },
    periodChange(period) {
      if (period === 'WEEKLY') {
        this.typePeriod = 'WEEKLY';
        this.allowDatesFrom = (val) => new Date(val).getDay() === 1;
      }
      if (period === 'MONTHLY') {
        this.typePeriod = 'MONTHLY';
      }
    },
    periodMonth() {
      this.menuMonthDate = false;
      const date = new Date(this.dateMonth);
      const year = date.getFullYear();
      const month = date.getMonth();
      const lastDay = new Date(year, month + 1, 0).getDate();
      if (this.typePeriod === 'WEEKLY') {
        this.form.periodStart = null;
        this.form.periodEnd = null;
        return;
      }
      this.form.periodStart = dayjs(this.dateMonth).format('YYYY-MM-DD');
      this.form.periodEnd = dayjs(this.dateMonth).format(`YYYY-MM-${lastDay}`);
    },
    periodWeek() {
      this.menuFromDate = false;
      this.form.periodEnd = dayjs(this.form.periodStart).add(6, 'days').format('YYYY-MM-DD');
    },
    invoiceCommonData() {
      this.isLoading = true;
      this.$_services.invoiceData.invoiceCommonData()
        .then((res) => {
          this.form.bankAcc = res.data.bankAccountName;
          this.form.bankAtt = res.data.bankAccountNumber;
          this.form.bankName = res.data.bankName;
          this.form.signerLeftName = res.data.firstSignerName;
          this.form.signerLeftPosition = res.data.firstSignerPosition;
          this.form.signerRightName = res.data.secondSignerName;
          this.form.signerRightPosition = res.data.secondSignerPosition;
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
</style>
