<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <span class="font-weight-bold">{{$_strings.invoice.TITLE_CREATE}}</span>
      </v-col>
    </v-row>
    <v-sheet>
      <v-row class="pa-2">
        <v-col cols="12" sm="9" md="8">
          <v-row align="center">
            <v-col
              cols="auto"
              class="pr-0"
            >
              <v-menu
                ref="menuDateFrom"
                v-model="menuDateFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    class="caption"
                    placeholder="From Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    :value="dayjs(filters.dateFrom).format('DD-MM-YYYY')"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.dateFrom"
                  no-title
                  :max="filters.dateTo"
                  @change="menuDateFrom = false"
                  locale="id"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="px-1" cols="auto">
              <span>-</span>
            </v-col>
            <v-col
              cols="auto"
              class="px-0"
            >
              <v-menu
                ref="menuDateTo"
                v-model="menuDateTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    hide-details
                    class="caption"
                    outlined
                    :value="dayjs(filters.dateTo).format('DD-MM-YYYY')"
                    placeholder="To Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="filters.dateFrom"
                  v-model="filters.dateTo"
                  no-title
                  @change="menuDateTo = false"
                  locale="id"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-row align="center" justify-md="end">
            <v-col
              cols="auto"
            >
              <span class="order-checked mr-2">Terpilih : {{form.shipmentList.length}} Pesanan</span>
              <v-btn
                :disabled="!form.shipmentList.length"
                color="primary"
                elevation="1"
                small
                @click="$refs.dialogCreateInvoice.dialog = true"
              >{{$_strings.invoice.TITLE_CREATE}}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            calculate-widths
            :items="itemsInvoice"
            item-key="noInvoice"
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`item.checkbox`]={item}>
              <v-checkbox @change="(e) => toggleCheckbox(e, item)" class="itemCheck" ></v-checkbox>
            </template>
            <template v-slot:[`item.shipmentCargoTitle`]={item}>
              <p class="ma-0">{{ item.shipmentCargoTitle }}</p>
              <span class="caption">{{ dateFormat(item.shipmentDate) }}</span>
            </template>
            <template v-slot:[`item.shipperName`]={item}>
              <p class="ma-0">{{ item.shipperName }}</p>
              <span>{{ item.inputBy }}</span>
            </template>
            <template v-slot:[`item.transporterAbbreviation`]={item}>
              <p class="ma-0">{{ item.transporterAbbreviation }}</p>
              <span>{{ item.inputBy }}</span>
            </template>
            <template v-slot:[`item.transportTypeName`]={item}>
              <span>{{ item.transportTypeName }}</span>
              <br/>
              <span class="caption">{{ item.licencePlate }}</span>
            </template>
            <template v-slot:[`item.grandTotalAmount`]={item}>
              <p class="ma-0">Rp. {{ formatAmount(item.grandTotalAmount) }}</p>
              <span class="caption">{{ typePriceInvoice(item.typePrice) }}</span>
            </template>
            <template v-slot:[`item.status`]={item}>
              <p class="ma-0">{{ item.status }}</p>
              <span class="caption">{{ dateFormat(item.dateStatus) }}</span>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.ORDER_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <DialogCreateInvoice
      ref="dialogCreateInvoice"
      @createInvoice="createInvoice"
      :form="form"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DialogCreateInvoice from './Dialog/CreateInvoice.vue';
import { dateFormat, thousandSeparated, typePriceInvoice } from '../../helper/commonHelpers';

export default {
  components: {
    DialogCreateInvoice,
  },
  data() {
    return {
      isLoadingCompanyList: false,
      itemsCompany: [],
      itemsInvoice: [],
      headers: [
        {
          text: this.$_strings.invoice.SELECT,
          value: 'checkbox',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentCargoTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'shipperCompanyAbbreviation',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ADD_TRANSPORT,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'grandTotalAmount',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.INVOICE_DOCUMENT,
          value: 'status',
          class: 'white--text primary text-capitalize',
        },
      ],
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },

      invoiceListTotalEntry: 0,
      menuDateFrom: false,
      menuDateTo: false,
      filters: {
        dateFrom: dayjs().subtract(1, 'months').format('YYYY-MM-DD'),
        dateTo: dayjs().format('YYYY-MM-DD'),
      },
      form: {
        paymentPeriod: '',
        periodStart: '',
        periodEnd: '',
        shipmentList: [
        ],
        bankAcc: '',
        bankAtt: '',
        bankName: '',
        signerLeftName: '',
        signerLeftPosition: '',
        signerRightName: '',
        signerRightPosition: '',
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchInvoice();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.fetchInvoice();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    dateFormat,
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    typePriceInvoice(typePrice) {
      return typePriceInvoice(typePrice);
    },
    async fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const { dateFrom, dateTo, companyId } = this.filters;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: '',
        dateFrom,
        dateTo,
        companyId,
      };
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
        if (!this.itemsInvoice) return;
      }
      try {
        this.$root.$loading.show();
        const result = await this.$_services.invoice.fetchInvoiceOrder(filters);
        this.itemsInvoice = result.data.contents;
        this.invoiceListTotalEntry = result.data.totalData;
      } finally {
        this.$root.$loading.hide();
      }
    },
    toggleCheckbox(event, item) {
      if (!event) {
        const findTargetIndex = this.form.shipmentList.findIndex((e) => e.id === item.id);
        this.form.shipmentList.splice(findTargetIndex, 1);
        return;
      }
      this.form.shipmentList.push({
        id: item.id,
        shipmentCargoId: item.shipmentCargoId,
        shipmentCargoTitle: item.shipmentCargoTitle,
      });
    },
    async createInvoice() {
      const { dateFrom, dateTo } = this.filters;
      this.form.periodStart = dayjs(dateFrom).format('YYYY-MM-DD');
      this.form.periodEnd = dayjs(dateTo).format('YYYY-MM-DD');
      try {
        this.$root.$loading.show();
        await this.$_services.invoice.createFixedPriceInvoice(this.form);
        this.$dialog.notify.success(this.$_strings.invoice.SUCCESS_INVOICE_MESSAGE_TEXT);
        this.$router.push('/main/invoice-transporter-fixed-price/list');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
