var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$_strings.invoice.TITLE_CREATE))])])],1),_c('v-sheet',[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"8"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menuDateFrom",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"From Date","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.dayjs(_vm.filters.dateFrom).format('DD-MM-YYYY')}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","max":_vm.filters.dateTo,"locale":"id"},on:{"change":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.filters.dateFrom),callback:function ($$v) {_vm.$set(_vm.filters, "dateFrom", $$v)},expression:"filters.dateFrom"}})],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"auto"}},[_c('span',[_vm._v("-")])]),_c('v-col',{staticClass:"px-0",attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menuDateTo",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","value":_vm.dayjs(_vm.filters.dateTo).format('DD-MM-YYYY'),"placeholder":"To Date","prepend-inner-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{attrs:{"min":_vm.filters.dateFrom,"no-title":"","locale":"id"},on:{"change":function($event){_vm.menuDateTo = false}},model:{value:(_vm.filters.dateTo),callback:function ($$v) {_vm.$set(_vm.filters, "dateTo", $$v)},expression:"filters.dateTo"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-row',{attrs:{"align":"center","justify-md":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"order-checked mr-2"},[_vm._v("Terpilih : "+_vm._s(_vm.form.shipmentList.length)+" Pesanan")]),_c('v-btn',{attrs:{"disabled":!_vm.form.shipmentList.length,"color":"primary","elevation":"1","small":""},on:{"click":function($event){_vm.$refs.dialogCreateInvoice.dialog = true}}},[_vm._v(_vm._s(_vm.$_strings.invoice.TITLE_CREATE))])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"calculate-widths":"","items":_vm.itemsInvoice,"item-key":"noInvoice","server-items-length":_vm.invoiceListTotalEntry,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"itemCheck",on:{"change":function (e) { return _vm.toggleCheckbox(e, item); }}})]}},{key:"item.shipmentCargoTitle",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.shipmentCargoTitle))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(item.shipmentDate)))])]}},{key:"item.shipperName",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.shipperName))]),_c('span',[_vm._v(_vm._s(item.inputBy))])]}},{key:"item.transporterAbbreviation",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.transporterAbbreviation))]),_c('span',[_vm._v(_vm._s(item.inputBy))])]}},{key:"item.transportTypeName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transportTypeName))]),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.licencePlate))])]}},{key:"item.grandTotalAmount",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v("Rp. "+_vm._s(_vm.formatAmount(item.grandTotalAmount)))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.typePriceInvoice(item.typePrice)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.status))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(item.dateStatus)))])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.invoice.ORDER_LIST)+" "),(_vm.itemsInvoice.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1),_c('DialogCreateInvoice',{ref:"dialogCreateInvoice",attrs:{"form":_vm.form},on:{"createInvoice":_vm.createInvoice}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }